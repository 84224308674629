const firebaseConfig = {
  apiKey: "AIzaSyDUlXGqiMiCxDUD9NZdoUtJw-_eTRa2A0s",
  authDomain: "quiklearn-dev.firebaseapp.com",
  projectId: "quiklearn-dev",
  storageBucket: "quiklearn-dev.appspot.com",
  messagingSenderId: "827968787849",
  appId: "1:827968787849:web:7f5f871473bd8b84a0fd58",
  measurementId: "G-PCK9KFM5YP"
};

export default firebaseConfig;
